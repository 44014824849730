import React, { Component } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import  logo  from './Logo192.png';
import "./Header.css"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AboutPage from '../Pages/AboutPage';


export default class Header extends Component {
  render() {
  
    return (
      
      <> 
      <Navbar collapseOnSelect expand="md"  >
        <Container>
          <Navbar.Brand href="/" >
            <img
                src={logo}
                heifht="49"
                width="75"
                className="d-inline-block align-top nav-logo"
                alt="Logo"
            />
          </Navbar.Brand>


          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse style={{flexGrow: 0}} id="responsive-navbar-nav" menuVariant="blue">
              <Nav className="mr-auto navbar-menu">
             {/* О центре */}
                  <NavDropdown
              id="nav-dropdown-dark-example drop"
              title="О центре"
              menuVariant="white"
            >
              <NavDropdown.Item href="/about" >Дата создания</NavDropdown.Item>
              <NavDropdown.Item href="/about">Учредители</NavDropdown.Item>
              <NavDropdown.Item href="/about">Место осуществления образовательной деятельности</NavDropdown.Item>
              <NavDropdown.Item href="/about">Оффициальный адрес сайта</NavDropdown.Item>
              <NavDropdown.Item href="/about">Структура АНО ДПО ЦПК ПАРТНЕР</NavDropdown.Item>
              <NavDropdown.Item href="/about">Договор об оказании платных образовательных услугах</NavDropdown.Item>
              <NavDropdown.Item href="/about">Лицензии, разрешения, свидетельства</NavDropdown.Item>
              <NavDropdown.Item href="/about">Локальные нормативный акты</NavDropdown.Item>
              <NavDropdown.Item href="/about">Уставные документы</NavDropdown.Item>
              <NavDropdown.Item href="/about">Образовательные стандарты</NavDropdown.Item>
              <NavDropdown.Item href="/about">Кадровое обеспечение образовательного центра</NavDropdown.Item>
              <NavDropdown.Item href="/about">Руководство</NavDropdown.Item>
              <NavDropdown.Item href="/about">Материально-техническое обеспечение и особенность процесса</NavDropdown.Item>
              <NavDropdown.Item href="/about">Стипендии и иные средства материальной поддержки</NavDropdown.Item>
              <NavDropdown.Item href="/about">Платные образовательные услуги</NavDropdown.Item>
              <NavDropdown.Item href="/about">Финансово-хозяйственная деятельность</NavDropdown.Item>
              <NavDropdown.Item href="/about">Вакантные места для приема (перевода)</NavDropdown.Item>
              <NavDropdown.Item href="/about">Программы повышения квалификации</NavDropdown.Item>
              <NavDropdown.Item href="/about">Образцы заявок</NavDropdown.Item>
              <NavDropdown.Item href="/about">Предписания</NavDropdown.Item>
              <NavDropdown.Item href="/about">Статьи</NavDropdown.Item>
              <NavDropdown.Item href="/about">Образцы удостоверений</NavDropdown.Item>
              <NavDropdown.Item href="/about">Доступная среда</NavDropdown.Item>
              <NavDropdown.Item href="/about">Международное сотрудничество</NavDropdown.Item>
            </NavDropdown>
              <Nav>

            {/* Курсы */}
            <NavDropdown
              id="nav-dropdown-dark-example drop"
              title="Курсы"
              menuVariant="white"
            >
              <NavDropdown.Item href="/courses" >Промышленная безопасность</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Электробезопасность</NavDropdown.Item>
              <NavDropdown.Item href="/courses">ГО и ЧС</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Рабочие профессии</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Охрана труда</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Промышленная безопасность</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Экологическая безопасность</NavDropdown.Item>
              <NavDropdown.Item href="/courses">Профессиональная переподготовка кадров</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/courses">
                Другие направления
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
                  <Nav.Link href="/prices" > График и стоимость </Nav.Link> 
                  <Nav.Link href="http://promnado.online/">Учебный портал</Nav.Link> 
                  <Nav.Link className="last-nav" href="#contacts" > Контакты </Nav.Link>   
              </Nav>
          </Navbar.Collapse>
        </Container>

      </Navbar>
    </>
    )
  }
}
