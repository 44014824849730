import React from "react";
import logo from './logo.svg';
import './App.css';
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Homepage from './Pages/HomePage';
import Header from './Components/Header';
import AboutPage from "./Pages/AboutPage";
import CoursesPage from "./Pages/CoursesPage";

function App() {
  return (
    <Router>
       <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/courses' element={<CoursesPage />} />
      </Routes>
    </Router>
  );
}

export default App;
